.checklistContainer{
    width: 100%;
    height: auto;
    padding: 1rem;
    margin: 0 auto;
}
.checklistCard{
    width: 100%;
    height: auto;
    padding: 1rem;
    text-align: center;
    cursor: pointer;
}
.checklistCard img{
    height: 65%;
    display: block;
    margin: 0 auto;
}
.checklistCard h3{
    margin-top: .4rem;
    font-size: 1.5rem;
}
.avtarImg {
  width: 200px;
  height: 200px;
    margin: 0 auto;
	background-color: transparent;
	border-radius: 50%;
	padding: 0;
	position: relative;
	transition: background-color 0.5s ease-in-out;
	-webkit-transition: background-color 0.8s ease-in-out;
display: flex;
justify-content: center;
align-items: center;
}
.avtarImg::before {
	position: absolute;
	content: '';
	background-color:#fff5f8;
	z-index: -1;
	border-radius: 50%;
	width: 100%;
	height: 100%;
	transition: all 0.6s ease-in-out;
	top: 0;
	left: 0;
	right: 0;
	margin: 0 auto;
	text-align: center;
	/* transform: scale(0); */
	
}
.avtarImg::after {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0); /* Combined translate and scale */
	content: '';
	background-color: #ffe7ed;
	z-index: -1;
	border-radius: 50%;
	width: 100%;
	height: 100%;
	transition: all 0.5s ease-in-out;
}

.checklistCard:hover .avtarImg::after {
	transform: translate(-50%, -50%) scale(1); /* Combined translate and scale on hover */
}






/* **************** */
.listHeader{
    width: 100%;
    height: auto;
    background-color: #2cc4e8;
    color: #fff;
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}
.listHeader h3{
    font-weight: bold;
}
.filterArea{
    width: fit-content;
    height: auto;
}
.selectSearch{
    min-width: 200px;
    margin: 0 10px;
}
/* ***********table container************ */
.tableContainer{
    width: 100%;
    background-color: whitesmoke;
    margin-top: 2rem;
}
.tableContainer table td {
  width: 33.3%;
}
table thead{
    padding: .5rem;
}
.checklistTabBtn{
    width: 90%;
    height: auto;
    margin-right: 5%;
    margin-left: 5%;
    padding: 1rem;
    border: none;
    border-radius: 8px;
    transition: .5s;
    box-shadow: 0 7px 0 #d1d1d3;
}
.checklistTabBtn:hover
{
    filter: brightness(0.9);
    transition: .5s;
}
.active{
    background-color: #addfeb!important;
    box-shadow: 0 7px 0 #6093ab;
    color: #333;
}
.tabParentChecklist{
    width: 100%;
    padding: 1rem;
}
.tabParentChecklist a{
    width: 80%;
}



/* ******************** */
.trackerHeader{
    width: 100%;
    height: auto;
    background-color: #008dd0;
    padding: 1rem;
    color:#fff;
    border-radius: 10px;
    margin: 1rem 0;    
    display: flex;
    justify-content: space-between;
}
.trackerFormContainer{
    width: 100%;
    height: auto;
    padding:1rem;
}
.trackerFormContainer label{
    margin-bottom: 1rem;
    font-size: 22px;
}
.trackerFormContainer label b {
    color: rosybrown;
    margin-right: 1rem;
}
.trackerFormContainer .row input {
    margin-bottom: 1rem!important;
}


/* *************Event calendar********************** */
.calendarHeaderWrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.8rem;
}

.calendarTrackerSelect{
    width: 300px;
    max-width: 300px;
}
.eventCalendarSection{
    width: 100%;
    background-color: #f4f7fe;
    border-radius: 8px;
    margin-top: 2rem;
    /* display: grid;
    grid-template-columns: 2fr 8fr; */
    padding: 2rem;
}
.datePickerContainer{
    width: 100%;
    height: 100%;
}
/* ***modal** */
.dialogOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    
  }
  
  .dialogContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    width: 100%;
    max-width: 600px;
    text-align: center;
  }
  .dialogContent label {
    display: inherit;
    text-align: left!important;
    font-weight: bold;
    margin-bottom: .4rem;
  }
  .dialogContent input {
    background-color: #f6f4f4;
  }
  .dialogContent .eventCreateHeading {
    border-bottom: thin solid #dedede;
    padding-bottom: 1rem;
  }
  .closeDialog {
    position: absolute;
    top: 10px;
    right: 15px;
    background: #ff5782;
    border: none;
    font-size: 16px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #fff;
  }
  
  /* **************************** */
  .teamContentTabWrapper
  {
    width: 100%;
    height: auto;
    margin-top: 2rem;
  }
  .teamListingHeader
  {
    width: 100%;
    height: auto;
    background-color: #008dd0;
    padding: 1rem;
    border-radius: 1rem;
    color: #fff;
    display: flex;
    justify-content: space-evenly;
  }
  .teamListingWrapper{
    width: 100%;
  }
  .teamListingWrapper ol {
    list-style: none;
  }
  .teamListingWrapper ol li {
    display: flex;
    justify-content: space-evenly;
    padding: 1rem;
    background-color: #fff;
    border-bottom: thin solid #dedede;
    font-size: 1.2rem;
  }
  .ctaTeam{
    display: flex;
    gap: 1rem;
  }
  .ctaTeam p {
    cursor: pointer;
  }
  .ctaTeam p i {
    margin-right: .4rem;
  }
  .ctaTeam p.previewTeam {
    color: #1dbbe6;
  }
  .ctaTeam p.editTeam {
    color: green;
  }
  .ctaTeam p.delTeam {
    color: red;
  }
  
  .otherTeamInvited {
    border-bottom: thin solid #dedede;
    padding: 1rem;
    text-align: center;
    font-size: 1.5rem;
    color: gray;
  }
  .successBtn {
    width: fit-content;
    height: fit-content;
    padding: .5rem 3rem;
    font-size: 1.2rem;
    border-radius: 6px;
    border: none;
    background-color: green;
    color: #fff;
  }
  .teamNavArea {
    display: flex;
    justify-content: space-between;
    padding: 0 3rem;
  }
  .createTeamDialog
  {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .createTeamDialogContentWrapper {
    max-width: 400px;
    width: 100%;
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    position: relative;
  }
  .closeBtn {
    width: 30px;
    height: 30px;
    background-color: #008dd0;
    color: #fff;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    position: absolute;
    right: -10px;
    top: -10px;
    line-height: 10px;
  }
  .deleteDialogCtaWrapper {
    display: flex;
    gap: .5rem;
    justify-content: center;
  }

.addFlex {
  display: flex;
}
  /* ************************* */
  @media all and (max-width: 975px) and (min-width: 320px) {
   .calendarHeaderWrapper {
    flex-direction: column;
    align-items: flex-start;
   } 
   .calendarHeaderWrapper .addFlex {
    flex-direction: column;
   }
   .calendarHeaderWrapper h1 {
    margin: 1.5rem 0;
   }
   .eventCalendarSection {
    padding: 0;
   }
  }


  /* ******************** */
  .trackerModalSection {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1000;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
    
  }
  .trackerInnerWrapper {
    max-width: 400px;
    width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 8px;
    padding: 1.5rem;
    position: relative;
  }
  .trackerModalCrossBtn {
    border: none;
    position: absolute;
    top: 0px;
    right: 10px;
    background-color: #ff5782;
    border-radius: 50%;
    color: #fff;
    width: 30px;
    height: 30px;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  .trackerModalHeading {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: thin solid #dedede;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  .trackerQuestionModalWrapper b{
      display: block;
      margin-top: 15px;
  }
  .trackerInnerWrapper button {
    margin-left: auto;
    margin-top: 20px;
    display: block;
  }
