.pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pageItem {
    margin: 0 5px;
  }
  
  .pageLink {
    border: 1px solid #2cc4e8;
    background: #fff;
    color: #2cc4e8;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .pageLink:hover {
    background-color: #2cc4e8;
    color: #fff;
  }
  
  .pageLinkDisabled {
    color: #ccc;
    cursor: not-allowed;
    background: #f8f9fa;
  }
  
  .activePageLink {
    background-color: #2cc4e8;
    color: #fff;
  }
  .pagination li {
    background-color: #fff!important;
    width: fit-content!important;
    padding: 0px!important;
  }
  @media only screen and (min-width: 1700px){
    .pagination li {
        background-color: #fff!important;
        width: fit-content!important;
        padding: 0px!important;
      }
}
.recordText {
    text-align: center;
}

.resultsPerPageSelect{
  width: 100%;
  max-width: 65px;
    height: 30px;
    background-color: rgba(106, 192, 227, 0.28);
    border-radius: 8px;
    border: none;
    padding: 0 5px;
    font-size: 13px;
    outline: none;
}