
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap'); */
a{
  color:#333
}
@font-face {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Regular"),
    url("./fonts/sogeo-ui/Segoe UI.woff") format("woff");
}

/* @font-face {
    font-family: 'Segoe UI Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Italic'), url('Segoe UI Italic.woff') format('woff');
    } */

@font-face {
  font-family: "Segoe UI Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Bold"),
    url("./fonts/sogeo-ui/Segoe UI Bold.woff") format("woff");
}

/* @font-face {
    font-family: 'Segoe UI Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Bold Italic'), url('Segoe UI Bold Italic.woff') format('woff');
    } */

body {
  font-family: "Segoe UI Regular";
}
h1,h2,h3,p {
  margin: 0;
}

.hide{
  display: none!important;
}
.error-container{
  display: none;
  color:red;
}
#error-container{
  color:red;
}
.input-box-wrapper.error input{
  border: 1px solid red;
}
.danger
{
  background-color: #e64040!important;
}
.input-box-wrapper.error .error-container{
  display: block;
}
/* *******Modal box********** */
.modal-section {
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}
.modal-wrapper {
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  border: 3px solid var(--skyblue);
  height: auto;
  border-radius: 15px;
  padding: 20px;


}
.modal-wrapper p {
  text-align: center;
  color: var(--skyblue);
  font-size: 16px;
  margin-bottom: 10px;
}
.modal-wrapper .modal-btns-wrapper {
  width: fit-content;
  margin: 10px auto;
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
}
/* ******************** */
.dialog_box{
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialog-box-body{
  width: 100%;
  max-width: 700px;
  height: auto;
  border-radius: 8px;
  background-color: white;
  padding: 20px;
  position: relative;
}
.dialog-box-body h2{
  text-align: center;
}
.dialog-box-body p{
  text-align: justify;
  margin-top: 10px;
  font-size: 16px;

}
.dialog-box-close{
  width: 30px;
  height: 30px;
  background-color: whitesmoke;
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: 10;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: thin solid #dedede;
  cursor: pointer;
}


.formvalidation-error-text {
  font-size: 14px;
  color: red;
  margin: 0;
}
.site-heading {
  text-align: center;
  margin: 0;
  font-size: 36px;
}
.blue-button {
  width: fit-content;
  padding: 6px 20px;
  background-color: var(--skyblue);
  color: white;
  border: none;
  height: fit-content;
  border-radius: 0.4rem;
}
.blue-button[disabled]{
  background-color: #dedede!important;
}
.BluetextUnderHeading {
  text-align: center;
  color: var(--skyblue);
}
.marginZero {
  margin: 0;
}
.marginTopH6 {
  margin-top: 3.5rem !important;
}
.margintop2rem {
  margin-top: 1rem !important;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90% !important;
}
.fontWeightSix {
  font-weight: 600;
}
.mt-custom {
  margin-top: 5rem;
}

:root {
  --blue: #2b2a81;
  --sky: #bde7fb;
  --skyblue: #008dd0;
  --lightgreen: #00dea9;
  --lightgray: #ecf0ef;
  --slightlyblue: #eff9fb;
}

.sidebar-section {
  width: 100%;
  z-index: 1200;
  max-width: 250px;
  height: 100vh;
  background-color: white;
}
.logo-wrapper {
  width: 100%;
  height: auto;
}
.logo-wrapper img {
  width: 100%;
  height: auto;
}
.sidebar-list-area {
  width: 100%;
  background-color: white;
  padding: 25px 0;
}
.sidebar-list-area ul {
  list-style: none;
  padding: 0;
}
.sidebar-list-area ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  border-bottom: 1px solid #707070;
}
.sidebar-list-area ul li .sidebar-list-bullet {
  width: 9px;
  height: 9px;
  flex-shrink: 0;
  background-color: var(--blue);
  border-radius: 50%;
  margin: 3px 10px 0 16px;
}
.sidebar-list-bullet-img {
  margin: 3px 10px 0 3px;
  flex-shrink: 0;
}
.sidebar-list-bullet-img img {
  width: 35px;
  height: 35px;
  object-fit: contain;
  flex-shrink: 0;
}
.sidebar-list-area ul li a {
  text-decoration: none;
  font-size: 18.5px;
  font-weight: 400;
  letter-spacing: -0.6px;
  text-align: left;
  color: #2a2a2a;
  display: flex;
  align-items: center;
  white-space: nowrap; 
}

/* ************signup screen**************** */

.signup-section-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
}
.signup-birds {
  /* height: 220px; */
  width: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 59%;
  z-index: 1;
}
.signup-ground {
  width: auto;
  height: 180px;
  position: fixed;
  bottom: 0;
  right: 0;
}
.signup-inner-parent {
  width: -webkit-fill-available;
  height: auto;
  padding: 10px 20px;
}
.signup-header-area {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.signup-header-content-wrapper {
  width: fit-content;
  height: fit-content;
  display: flex;
}
.registration-links {
  color: #2a2a2a;
  text-decoration: none;
  margin-right: 7px;
}
.search-box {
  max-width: 280px;
  border: 1.5px solid #c7c7c7;
  margin: 0px 10px;
  padding: 2px 8px;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
}
.gray-button {
  color: #2a2a2a;
  background-color: #dedede;
  border-radius: 6px;
  padding: 5px 26px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  text-transform: uppercase;
  max-width: 100px;
  width: 100%;
  margin-right: 10px;
  border: none;
}

.button-disabled {
  background-color: lightgray !important; 
  cursor: not-allowed !important;
}
.warning{
  color: red;
}
.signup-form-main-area {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 10;
}
.signup-form-wrapper {
  max-width: 330px;
  width: 100%;
  margin: 20px 8rem;
}
.signup-form-wrapper h2 {
  text-align: center;
  color: #433559;
}

.login-link {
  color: #2b2a81;
  text-decoration: underline;
  cursor: pointer;
}
.input-box-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  
}

.input-box-wrapper p {
  font-size: 16px;
  margin-bottom: 5px;
}
.signup-form-wrapper form {
  padding: 20px 0 0 0;
}
.signup-form-wrapper .login-form {
  padding: 15px 0 0 0;
}
.input-box-wrapper .signup-box-input,
select {
  width: 100%;
  height: 35px;
  background-color: rgba(106, 192, 227, 0.28);
  border-radius: 10px;
  border: none;
  padding: 0 6px;
  font-size: 16px;
  outline: none;
}
.signup-box-input::placeholder{
  color: #333;
}
.input-box-wrapper label {
  font-size: 14px;
  margin: 2px;
  font-weight: 600;
}
.submit-btn {
  display: block;
  margin: 0 auto;
  border: none;
  font-weight: 600;
  background-color: #6ac0e3;
  margin-top: 5px;
  cursor: pointer;
  padding: 5px 20px;
  width: 160px;
  max-width: unset;
}
.social-icons-wrapper {
  width: 100%;
  height: auto;
}
.social-icon-circle {
  width: 35px;
  height: 35px;
  background-color: #8c8b8c;
  border-radius: 50%;
  margin: 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dbdbdb;
}
.social-icon-circle i {
  font-size: 26px;
  color: white;
}
.social-icon-circle img {
  width: 40px;
}
a {
  text-decoration: none;
}
a:hover {
  color: #2a2a2a;
}
/* .loginfields::-webkit-input-placeholder {
    color: #000;
    font-weight: 600;
} */
.forgotPass {
  color: #000;
  font-weight: 600;
}
.or-txt {
  text-align: center;
  position: relative;
  margin-top: 10px;
}
.or-txt::after {
  content: "";
  position: absolute;
  left: 10px;
  top: 14px;
  width: 41%;
  height: 1px;
  background-color: #000;
}
.or-txt::before {
  content: "";
  position: absolute;
  right: 10px;
  top: 14px;
  width: 41%;
  height: 1px;
  background-color: #000;
}
.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: none;
  background-color: #d6edf7;
  border-radius: 0.64rem;
  padding: 5px;
}
.react-date-picker {
  width: 100%;
}
.password-eye-icon{
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  cursor: pointer;
}
/* *************mobile-header-section************************ */
.mobile-header-section {
  width: 100%;
  height: auto;
  padding: 5px 10px;
  background-color: #0c206e;
  color: white;
  display: flex;
  justify-content: space-between;
  display: none;
}
.menuicon-wrapper {
  width: fit-content;
}
.menuicon-wrapper i {
  font-size: 26px;
  cursor: pointer;
}

#nav-icon4,
#nav-icon3 {
  width: 54px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  margin-top: 23px;
}

/* Icon 4 */

#nav-icon4 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
  top: 9px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
  top: 18px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
  -webkit-transform: rotate(41deg);
  -moz-transform: rotate(41deg);
  -o-transform: rotate(41deg);
  transform: rotate(41deg);
  top: 0;
  left: 10px;
}

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 30px;
  left: 10px;
}

#nav-icon1 span,
#nav-icon3 span,
#nav-icon4 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 40px;
  background: #fff;
  border-radius: 4px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

/* ************** */

/* Icon 4 */

#nav-icon3 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon3 span:nth-child(2) {
  top: 9px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon3 span:nth-child(3) {
  top: 18px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon3.open span:nth-child(1) {
  -webkit-transform: rotate(41deg);
  -moz-transform: rotate(41deg);
  -o-transform: rotate(41deg);
  transform: rotate(41deg);
  top: 0;
  left: 10px;
}

#nav-icon3.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 30px;
  left: 10px;
}

#nav-icon3 span,
#nav-icon4 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 40px;
  background: #fff;
  border-radius: 4px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

/* ***************************8welcome-section************************ */

.welcome-section {
  width: 100%;
  height: auto;
  display: flex;
}
.welcome-main-area {
  width: -webkit-fill-available;
  padding: 20px;
  position: relative;
  z-index: 10;
}
.welcome-row-wrapper {
  width: 100%;
  max-width: 92%;
  margin: 0 auto;
  margin-top: 4.3rem;
}
.welcomeCol-wrapper {
  height: 100%;
  width: 100%;
  padding: 1px;
  position: relative;
}
.welcomeCol-wrapper h2 {
  letter-spacing: 0px;
  margin-top: -9px;
}
.welcomeCol-wrapper h1{
  margin-top: -9px;
}
.welcomeCol-wrapper p {
  font-size: 17px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.welcome-btn {
  background-color: var(--skyblue);
  color: white;
  max-width: unset;
  width: fit-content;
  border: none;
  /* position: absolute; */
  bottom: 0%;
  left: 50%;
  /* margin-left: -88px; */
}
.registerLoginBtnParent {
  width: 100%;
  position: absolute;
  bottom: -2px;
  display: flex;
  justify-content: center;
}
.home-signin-btn {
  left: 45%!important;
}
.home-signout-btn {
  right: 15%!important;
  left: unset;
}

.welcome-img-video-wrapper {
  width: 100%;
  height: 100%;
  background-color: whitesmoke;
  border: 6px solid var(--blue);
  border-radius: 10px;
  overflow: hidden;
}

.welcome-img-video-wrapper img {
  width: 100%;
  height: 100%;
  transform: scale(1.1);
  object-fit: cover;
}
.patient-txt {
  text-align: center !important;
  color: var(--skyblue);
  font-weight: 600;
  font-size: 22px !important;
  margin-top: -5px;
}

/* *********************services-section******************** */

.services-section {
  width: 100%;
  height: auto;
  display: flex;
}
.service-main-area {
  width: 100%;
  height: auto;
}
.service-row-wrapper {
  width: 100%;
  height: 85%;
  display: flex;
  align-items: center;
}
.service-cards-area {
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
}
.service-card-paren {
  width: 100%;
  height: auto;
  background-color: #00dea9;
}
  .service-card-parent p {
    font-size: 18px;
    margin-top: 12px;
    margin-bottom: 0;
    line-height: 1.1;
  }
.service-card-wrapper {
  width: 100%;
  height: 170px;
  background-color: #addfeb;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 6px 33px -6px rgba(56, 32, 32, 0.28);
  overflow: hidden;
  padding: 1rem;
  position: relative;
  perspective: 1000px; /* Needed for the flip effect */
}
.service-card-front, .service-card-back {
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Ensures the back side is not visible when flipped */
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.9s cubic-bezier(0.4, 0.0, 0.2, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px; /* Optional: for rounded corners */
}
.service-card-front {
  background-color: #addfeb; /* Visible side */
}
.service-card-back {
  background-color: whitesmoke; /* Back side where text will be */
  transform: rotateY(180deg); /* Start rotated */
  overflow-y: auto; /* Allows for scrolling */
  padding: 10px; /* Padding for content */
}
.servicecardbacktext {
   font-size: .9rem;
   line-height: 20px;
   text-align: left;
   padding: 9px;
   color: #000;
   max-height: 150px;
   
}
.service-card-wrapper:hover .service-card-front {
  transform: rotateY(180deg); /* Rotate to show the back on hover */
}

.service-card-wrapper:hover .service-card-back {
  transform: rotateY(360deg); /* Rotate back element to be visible */
}
.service-card-wrapper img{
  /* width: 70%; */
  height: 85%;
  object-fit: contain;
}

/* *************contact-section-wrapper************** */
.contact-section-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
}
.contact-inner-parent {
  width: -webkit-fill-available;
  height: auto;
  padding: 20px;
}
.contact-form-main-area {
  width: 100%;
  height: auto;
  padding: 10px;
}
.contact-form-wrapper {
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
}
/* .contact-form-wrapper  h1{
    font-weight: bold;
} */
.contact-form-wrapper label {
  display: block;
  font-weight: 500;
}
.custom-form-control {
  border-radius: 0.6rem;
  border: none;
  background-color: rgba(106, 192, 227, 0.28);
  padding: 5px 10px;
  outline: none;
  width: 100%;
  margin-top: 5px;
  resize: none;
}

/* **********Profile************* */

.profile-section-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
}
.profile-inner-parent {
  width: -webkit-fill-available;
  height: auto;
}
.profile-main-area {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 10px;
}
/* .profile-main-area h1{
    font-weight: bold;
} */
.profile-main-area .service-card-wrapper {
  height: 140px;
}

.upload-picture-form-wrapper{
  display: flex;
  align-items: flex-end;
  width: fit-content;
  margin: 0 auto;
}
.image-wrapper
{
  position: relative;
  width: 100%;
  height: 100%;

}
.preview_img_wrapper
{
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  padding: 8px;
}
.preview_img_wrapper img
{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.upload-img-cta-area {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
}
.upload-img-cta-area button
{
  margin-top: 1rem;
  font-size: 1.3rem;
  margin-left: 0;
  width: 100%;
  padding: 10px 35px;
}
.upload-profile-illustration
{
  min-height: 300px;
}


/* ***********Edit profile*********** */
.edit-profile-form-container
{
  width: 100%;
  height: auto;
  padding: 1rem;
  padding-top: 0;
}
/* ************Services packages************ */
.services-packages-section {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
}
/* .services-packages-section h1{
    font-size: 34px;
    font-weight: bold;
} */
.services-packages-inner-parent {
  width: -webkit-fill-available;
  height: auto;
}
.service-packages-main-area {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  height: auto;
  padding: 10px;
}
.servicepackage-card-wrapper {
  width: 100%;
  height: 100%;
  background-color: var(--slightlyblue);
  border-radius: 0.6rem;
  padding: 15px;
}

.servicepackage-card-wrapper img {
  width: 100%;
  max-width: 50%;
  display: block;
  margin: 0 auto;
  object-fit: contain;
  margin-bottom: 10px;
}
.servicepackage-card-wrapper p {
  text-align: center;
  font-size: 10px;
  margin: 0;
  margin-top: 4px;
}
.packages-card-wrapper {
  width: 100%;
  height: 100%;
  background-color: lightblue;
  border-radius: 1rem;
  padding: 10px;
}
.packages-card-wrapper h2 {
  text-align: center;
  font-size: 26px;
  font-weight: bold;
}
.packages-card-wrapper p {
  text-align: center;
  font-size: 13px;
  max-width: 90%;
  margin: 0 auto;
}
.services-packages-inner-parent .service-card-wrapper {
  height: 135px;
}

/* ************clinical-questions-section*************** */
.clinical-questions-section {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
}
.clinical-question-inner-parent {
  width: 82%;
  height: auto;
  margin: 0 auto;
  padding: 20px 0;
}
.clinical-question-main-area {
  width: 100%;
  height: auto;
  padding: 0 20px;
}
.clinical-question-main-area .slider-wrapper {
  width: 100%;
  height: auto;
  padding: 5px 0;
  margin-top: 1rem;
  overflow: hidden;
}

.clinical-question-main-area .slider-wrapper .owl-stage-outer {
  overflow: visible;
}

.clinical-question-main-area .slider-wrapper .item {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* padding-right: 25px; */
  position: relative;
  gap: 4px;
}

.clinical-question-main-area .slider-wrapper .item i {
  /* position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 10px; */
}

/* Adjustments for 100% zoom level */
@media (zoom: 1) {
  .clinical-question-main-area .slider-wrapper .item {
    padding-right: 20px;
  }

  .clinical-question-main-area .slider-wrapper .item i {
    right: 0;
    margin-left: 5px;
  }
}

/* Adjustments for smaller screens */
@media (max-width: 1200px) {
  .clinical-question-main-area .slider-wrapper .item {
    padding-right: 22px;
  }

  .clinical-question-main-area .slider-wrapper .item i {
    right: 2px;
    margin-left: 8px;
  }
}

/* Adjustments for larger screens */
@media (min-width: 1201px) {
  .clinical-question-main-area .slider-wrapper .item {
    /* padding-right: 28px; */
  }

  .clinical-question-main-area .slider-wrapper .item i {
    /* right: 2px; */
    /* margin-left: 12px; */
  }
}
.question-tab-button {
  width: 110px;
  height: 55px;
  border: 2.5px solid var(--blue);
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  cursor: pointer;
}
.question-tab-button p {
  font-size: 13px;
  margin: 0;
  font-weight: bold;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.question-tab-button.activebtn {
  background-color: #0c206e;
}
.question-tab-button.activebtn p {
  color: white;
}
.activeSection-wrapper {
  width: 100%;
  margin-top: 10px;
  padding: 1px;
}
.question-form-wrapper {
  max-width: 450px;
  width: 100%;
  margin: 10px auto;
  max-height: 55vh;
  overflow-y: auto;
  padding: 12px;
  padding-bottom: 0;
}
.question-form-wrapper::-webkit-scrollbar {
  width: 10px;
  position: relative;
  margin-left: 20px;
  left: -100px;
}
.question-form-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border: 1px solid var(--skyblue);
  border-radius: 0px;
}
.question-form-wrapper::-webkit-scrollbar-thumb {
  background: var(--skyblue);
  border-radius: 10px;
}
.form_container_sec{
    width: 100%;
    min-height: 260px;
}
.question-form-wrapper h2 {
  text-align: center;
  color: var(--skyblue);
  font-size: 24px;
  font-weight: bold;
}
.question-form-wrapper p {
  font-size: 14px;
  color: #2a2a2a;
  text-align: center;
  margin-bottom: 10px;
}
.question-form-wrapper label {
  color: var(--blue);
  font-weight: bold;
  margin-top: 10px;
  font-size: 18px;
  text-transform: capitalize;
  display: block;
}
.question-form-wrapper input,
.question-form-wrapper select {
  width: 100%;
  height: 35px;
  background-color: rgba(106, 192, 227, 0.28);
  border-radius: 10px;
  border: none;
  padding: 2px 7px;
  font-size: 16px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
.questions-both-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
}
.questions-both-btn-wrapper button {
  /* width: 100%; */
  text-transform: uppercase;
}
.questions-both-btn-wrapper button:first-child,.questions-both-btn-wrapper a{
  width: 48%!important;
}
.questions-both-btn-wrapper button{
  width: 48%!important;
}
.questions-both-btn-wrapper button,.questions-both-btn-wrapper a .finish-button{
  width: 100%;
}
.finish-button{
    display: block;
    background-color: #0c206e;
}
/* ***************trial-criteria-main-area********** */
.trial-criteria-main-area {
  width: 100%;
  height: auto;
}
.trial-criteria-buttons-wrapper {
  max-width: fit-content;
  height: auto;
  margin: 0 auto;
}

/* .trial-criteria-buttons-wrapper p{
    text-align: center;
    color: var(--skyblue);
    font-size: 16px;
    margin: 0;
} */
.light-theme-btn {
  width: 300px;
  background-color: white;
  border: 2px solid var(--blue);
  color: var(--blue);
  border-radius: 0.8rem;
  outline: none;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  line-height: 18px;
  height: 60px;
  transition: 0.5s;
  font-weight: bold;
}
.light-theme-btn i {
  color: var(--blue);
  font-weight: bold;
  font-size: 30px;
}
.light-theme-btn:hover {
  background-color: var(--blue);
  color: white;
  transition: 0.5s;
}
.light-theme-btn:hover i {
  color: white;
  transition: 0.5s;
}

.selected-dark-theme-btn {
  width: 300px;
  background-color:#0c206e;
  border: 2px solid var(--blue);
  color: white;
  border-radius: 0.8rem;
  outline: none;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  line-height: 18px;
  height: 60px;
  transition: 0.5s;
  font-weight: bold;
}

.selected-dark-theme-btn i {
  color: white;
  font-weight: bold;
  font-size: 30px;
}


/* ****************** */
.account-form-main-area label {
  font-size: 17px;
}
.account-form-wrapper {
  max-width: 650px !important;
}

/* ******************************* */

.cc-page-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
}
.main-area-inner-wrapper {
  width: -webkit-fill-available;
  height: auto;
  padding: 20px;
}
.new-page-content-wrapper {
  width: 100%;
  
}
.new-event-content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 1rem;
  height: 500px; 
  overflow-y: auto; 
  overflow-x: hidden;
}
.new-event-content-wrapper::-webkit-scrollbar {
  width: 6px;
  /* Adjust the width of the scrollbar */
}

.new-event-content-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--skyblue); /* The color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners of the scrollbar thumb */
  border: 3px solid var(--skyblue);
  /* Adds a border within the scrollbar thumb */
}

.new-event-content-wrapper::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
  /* The color of the scrollbar track */
}
.tabs-content-btns-section {
  width: 100%;
}
.tabs-button-wrapper {
  width: 100%;
  height: 80px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
.tabs-button-wrapper button {
  width: 32.7%;
  height: 45px;
  background-color: #dedede;
  border-radius: 0.7rem;
  color: var(--blue);
  font-weight: bold;
  font-size: 20px;
  border: none;
}
.tabs-button-wrapper button.activeTab {
  background-color: var(--blue);
  color: white;
}
.tabs-drugs-parent {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}
.tabs-drugs-parent ul {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabs-drugs-parent ul li {
  width: 31.7%;
  height: 45px;
  background-color: #dedede;
  border-radius: 0.7rem;
  color: var(--blue);
  font-weight: bold;
  font-size: 20px;
  border: none;
  margin-right: 10px;
  text-align: center;
}
.tabs-drugs-parent ul .react-tabs__tab--selected {
  background-color: var(--blue) !important;
  color: white;
}
.tab-content-wrapper-drugs {
  width: 100%;
}
.tab-content-wrapper-drugs table tbody {
  max-height: 400px;
}


.drugstatusTable-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 10px; 
}

.drugstatusTable-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border: 1px solid var(--skyblue); 
  border-radius: 5px; 
}

.drugstatusTable-wrapper::-webkit-scrollbar-thumb {
  background: var(--skyblue); 
  border-radius: 5px; 
}


.drugstatusTable-wrapper::-webkit-scrollbar {
  position: relative;
  margin-left: 20px; 
  left: -100px; 
}

.stats-drug-card-area {
  width: 100%;
  height: 160px;
  background-color: #fff;
  border-radius: 2rem;
  overflow: hidden;
}
.stats-drug-card-area .text-area {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.stats-drug-card-area .text-area p {
  max-width: 140px;
  text-align: center;
  line-height: 1.3;
  font-size: 16px;
  font-weight: 600;
}
.stats-drug-card-area button {
  width: 100%;
  height: 60px;
  background-color: var(--blue);
  color: white;
  font-size: 26px;
  padding: 0;
  border: none;
  margin: 0;
}

.row .col:nth-child(1) .stats-drug-card-area {
  border: 3px solid var(--blue);
}
.row .col:nth-child(1) .stats-drug-card-area button {
  background-color: var(--blue);
}

/* *** */

.row .col:nth-child(2) .stats-drug-card-area {
  border: 3px solid #9f2f7a;
}
.row .col:nth-child(2) .stats-drug-card-area button {
  background-color: #9f2f7a;
}

/* ******* */
.row .col:nth-child(3) .stats-drug-card-area {
  border: 3px solid #7638ae;
}
.row .col:nth-child(3) .stats-drug-card-area button {
  background-color: #7638ae;
}

/* ******* */
.row .col:nth-child(4) .stats-drug-card-area {
  border: 3px solid #1caaa0;
}
.row .col:nth-child(4) .stats-drug-card-area button {
  background-color: #1caaa0;
}
/* ******* */
.row .col:nth-child(5) .stats-drug-card-area {
  border: 3px solid #77ac5c;
}
.row .col:nth-child(5) .stats-drug-card-area button {
  background-color: #77ac5c;
}

/* **************security-settings******************* */
.security-settings-parent {
  max-width: 1050px;
  margin: 0 auto;
}
.security-settings-parent h2 {
  font-size: 26px;
}
.security-settings-form-wrapper {
  max-width: 300px;
  width: 100%;
  height: auto;
}
.form-btns-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.form-btns-wrapper input {
  width: 47.5% !important;
  max-width: unset;
  margin: 0;
}
/* ************account-settings************* */
.account-settings-parent {
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
}

/* *************drugsTable****************8 */
.drugsTable thead tr {
  width: 100%;
  background-color: #2cc4e8;
  color: white;
  margin-bottom: 20px;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
}
.drugsTable th {
  text-align: center;
}
.drugName {
  color: #2cc4e8;
  font-weight: 600;
}
.drugsTable tr span {
  color: #2cc4e8;
  font-size: 14px;
  text-align: center;
}
.drugsTable tbody tr {
  border-bottom: 1px solid #dedede;
  background-color: #fff;
}
.drugsTable tbody tr td{
  text-align: -webkit-center;
  vertical-align: middle;
  /* background-color: #fff; */
  padding: 1.5rem 0,5rem;
  max-width: 200px;
  border-bottom: unset!important;
  font-size: 16px!important;
}
.drugsTable td button{
  padding: 4px 20px;
  font-size: 18px!important;
  border-radius: 0.6rem!important;
}
.drugstableIcon {
  width: 25px;
  height: 25px;
  cursor: pointer;
  fill: red;
  margin: 0 7px;
  margin-bottom: 6px;
  flex-shrink: 0;
}
.centertd {
  display: block;
  text-align: center;
}
.accounts-form-card-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  border-bottom: 1px solid gray;
}
.accounts-form-card-wrapper .label {
  margin-right: 20px;
  font-size: 18px;
  font-weight: 550;
}
.paymentPlanName {
  font-weight: bold;
  color: #ffb300;
  margin-left: 20px;
  font-size: 20px;
}
.paymentPlanName span {
  color: gray;
  font-size: 14px;
  font-weight: normal;
}
/* ****************About page************ */

/* ****************About page************ */

.ceo-section{
  width: 100%;
  height: auto;
}
 .new-page-content{
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 1rem;
  height: 80vh; 
  overflow-y: auto; 
  overflow-x: hidden;
 }
.about-page-inner-container{
  max-width: 92%;
  margin: 0 auto;
}

.about-col-wrapper {
  width: 100%;
  height: 300px; /* Set a fixed height for scrollable area */
  padding: 10px;
  overflow-y: auto; /* Enable vertical scroll */
}

.about-col-wrapper h2 {
  text-align: center;
  color: var(--skyblue);
  margin-top: 20px;
}

.about-col-wrapper p{
  margin-top: 20px;
}

.ceo-profile-img-section {
  width: 100%;
  height: auto;
  text-align: center; /* Center the content */
}

.ceo-profile-img-section .img-wrapper {
  width: 70%;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
  margin-right: 45px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.ceo-profile-img-section .img-wrapper img {
  width: 100%;
  border-radius: 10px;
}

.ceo-profile-img-section .img-caption-area{
  width: 100%;
  height: auto;
}

.ceo-profile-img-section .img-caption-area p {
  color: var(--skyblue);
  margin-top: 10px;
}

.ceo-profile-img-section .img-caption-area .download-cv-btn {
  display: block;
  margin: 10px auto 0;
  padding: 10px 20px;
  background-color: var(--skyblue);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.team-section{
  width: 100%;
  height: auto;
}

.team-card-wrapper{
  width: 100%;
  height: auto;
}

.team-img-area{
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 2px solid var(--skyblue);
  overflow: hidden;
  margin: 0 auto;
}

.team-img-area img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-caption-area{
  width: 100%;
  height: auto;
}

.team-caption-area p{
  text-align: center;
  color: var(--skyblue);
}

.reachUs-section{
  width: 100%;
  height: auto;
}

.reachUs-col-wrapper{
  width: 100%;
  height: 100% ;
  display: flex;
  align-items: center;
}

.reachUs-col-wrapper h4{
  color: var(--skyblue);
  text-align: left;
}

.reachUs-col-wrapper iframe{
  width: 100%;
  height: 300px;
}
.reachUs-col-wrapper a {
  color: var(--skyblue); /* Use the custom sky blue variable */
  text-decoration: none; /* Removes underline */ /* Makes the link text bold */
}

.aboutPageWrapper .sidebar-section{
  position: fixed;
  z-index: 1;
}

.aboutPageWrapper .main-area-inner-wrapper{
  margin-left: 250px;
}

.aboutPageWrapper .custom-scroll::-webkit-scrollbar {
  width: 6px;
}

.aboutPageWrapper .custom-scroll::-webkit-scrollbar-thumb {
  background-color: var(--skyblue);
  border-radius: 3px;
}

.aboutPageWrapper .custom-scroll::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.new-page-content::-webkit-scrollbar {
  width: 6px; /* Adjust the width of the scrollbar */
}

.new-page-content::-webkit-scrollbar-thumb {
  background-color: var(--skyblue); /* The color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners of the scrollbar thumb */
  border: 3px solid var(--skyblue); /* Adds a border within the scrollbar thumb */
}

.new-page-content::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* The color of the scrollbar track */
}



/* ******************************* */
.profile-picture-content-wrapper{
  width: 100%;
  height: auto;
  padding: 20px;
  padding-top: 0;
  display: flex;
  flex-direction: row;
}
@media (max-width:600px) {
  .profile-picture-content-wrapper{
    display: flex;
    flex-direction: column-reverse;
  }
}
.profile-picture-content-wrapper .image-wrapper{
  width: 220px;
  height: 220px;
  min-width: 220px;
  margin-top: 20px;
  background-color: #addfeb;
  border-radius: 12px;
}
.editProfileAvatarWrapper {
  width: 100%;
}
.editProfileAvatarWrapper img {
  width: 80%;
}
.cta-wrapper{
  width: 100%;
  height: 100px;
  
}
.cta-wrapper button{
  max-width: 250px;
  position: relative;
}
.cta-wrapper button input{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}
/* ********************** */
.analytics-drug-table-container{
  display: none;
}
.analytics-drug-table-container.active{
  display: block!important;
}
/* ******************** */
.dropdown-eligibilty-container{
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  padding: 10px;
  padding-bottom: 20px;
}
.dropdown-eligibilty-container button:nth-of-type(1){
  height: fit-content;
  padding: 10px;
  font-weight: normal!important;
  position: relative;
}
.dropdown-eligibilty-container input{
  height: fit-content;
  width: 100%;
  padding: 5px 5px 5px 30px;
  margin-top: 10px;
  border: 2px solid var(--blue);
  color: var(--blue);
  border-radius: 0.8rem;
  outline: none;
  font-size: 16px;
}
.search-box-area{
  width: 100%;
  height: fit-content;
  position: relative;
}
.dropdown-eligibilty-container .search-box-area i{
  position: absolute;
  top: 22px;
  left: 10px;
  color: var(--blue);
}
.dropdown-eligibilty-container input::placeholder{
  color: var(--blue);
  font-weight: normal;

}
/* ******question options*********** */

.question-option-card{
  width: 100%;
  height: 110px;
  background-color: #fff;
  color: #0c206e;
  padding: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 19px;
  margin-top: 20px;
  cursor: pointer;
  border: 2px solid #0c206e;
}
.question-option-card.active{
  background-color: #0c206e!important;
  color: #fff!important;
}
/* ******************** */
/* .css-1s2u09g-control{
  background-color: #6ac0e3!important;
  color: white!important;
} */
/* ***************Team page*********** */
.team-content-wrapper{
  width: 100%;
  height: auto;
  padding: 20px;
}
.team-upload-btn{
  width: 140px;
  height: 140px;
  background-color: var(--skyblue);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.team-upload-btn i{
  font-size: 30px;
  font-weight: normal;
  color: white;

}
.team-content-wrapper .cta-wrapper{
  width: fit-content;
  height: auto;
  background-color: transparent;
}
.add-team-popup-wrapper{
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(8px);
  position: fixed;
  top: 0;
  left: 0;
  right:0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center ;
}
.addteam-popup-inner-area{
  max-width: 500px;
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 18px;
  border: 2px solid var(--blue);
  display: flex;
  padding: 20px;
  position: relative;
}
.addteam-popup-inner-area .img-area{
    width: 100%;
    max-width: 200px;
}
.addteam-popup-inner-area .img-area img{
  width: 90%;
  display: block;
  margin: 0 auto;
}
.addteam-popup-inner-area .addteam-form-area{
  width: -webkit-fill-available;
  height: auto;
}
.add-team-popup-wrapper .addteam-popup-inner-area .close-popup-button{
  width: 50px;
  height: 50px;
  background-color: var(--blue);
  color: white;
  position: absolute;
  top: -20px;
  right: -20px;
  z-index: 10;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
}




@media only screen 
  and (min-device-width : 1024px) 
  and (max-device-width : 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .drugsTable tbody tr td {
      max-width: 274px;
      min-width: 120px;
  }
    .drugsTable td button {
      font-size: 13px!important;
      margin-bottom: 10px;
  }
    .clinical-question-inner-parent {
      width: 100%;
    }
    .upload-picture-form-wrapper {
      flex-direction: column;
      align-items: flex-start;
    }
    .upload-picture-form-wrapper .image-wrapper {
      margin-bottom: 1rem;
    }
    .signup-header-content-wrapper .gray-button {
      display: flex;
      justify-content: center;
      padding: 5px 15px;
      width: 197px;
      max-width: unset;
    }
  
    .mobile-header-section {
      display: flex;
    }
    .hideInMobile {
      display: none;
    }
    .welcome-img-video-wrapper {
      margin-top: 20px;
    }
    .signup-header-area {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      position: fixed;
      height: 100vh;
      background-color: whitesmoke;
      z-index: 11;
      max-width: 240px;
      right: 0;
      padding: 10px;
      top: 71px;
      transition: 0.5s;
    }
    .signup-form-main-area {
      justify-content: center;
    }
    .welcome-row-wrapper {
      margin-top: 1.3rem;
    }
    .signup-inner-parent {
      padding: 0 20px;
    }
    .signup-header-area div {
      display: block;
      margin: 10px 0;
    }
    .signup-header-area div input {
      margin: 0;
    }
    .signup-form-wrapper {
      margin: 20px;
    }
    .hideInLeftInMobile {
      transition: 0.5s;
      transform: translateX(-240px);
      transition: 0.5s;
    }
    .headerHideinMobile {
      transition: 0.5s;
      transform: translateX(240px);
      transition: 0.5s;
    }
    .headerShowinMobile {
      transition: 0.5s;
      transform: translateX(0px);
      transition: 0.5s;
    }
    .showInLeftInMobile {
      transition: 0.5s;
      transform: translateX(0px);
      transition: 0.5s;
    }
    .sidebar-section {
      width: 100%;
      max-width: 240px;
      height: 100vh;
      position: fixed;
      display: block !important;
      left: 0;
      z-index: 12;
      bottom: 0;
      top: 70px;
      transition: 0.5s;
      background-color: white;
    }
    /* *****************service********* */
    .service-row-wrapper {
      height: auto;
      padding: 25px;
    }
    .service-packages-main-area .row .col {
      flex: 50%;
    }
    /* *************** */
    .security-settings-form-wrapper {
      margin: 0 auto;
    }
    /* ************ */
  
    .tabs-drugs-parent ul li{
      height: auto;
      font-size: 14px;
      padding: 5px 2px;
    }
    .aboutPageWrapper .main-area-inner-wrapper{
      margin: 0;
    }
}







/* *******************media query ******************* */
@media all and (max-width: 975px) and (min-width: 320px) {
  .drugsTable tbody tr td {
    max-width: 274px;
    min-width: 120px;
}
  .drugsTable td button {
    font-size: 13px!important;
    margin-bottom: 10px;
}
  .clinical-question-inner-parent {
    width: 100%;
  }
  .upload-picture-form-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .upload-picture-form-wrapper .image-wrapper {
    margin-bottom: 1rem;
  }
  .signup-header-content-wrapper .gray-button {
    display: flex;
    justify-content: center;
    padding: 5px 15px;
    width: 197px;
    max-width: unset;
  }

  .mobile-header-section {
    display: flex;
  }
  .hideInMobile {
    display: none;
  }
  .welcome-img-video-wrapper {
    margin-top: 20px;
  }
  .signup-header-area {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: fixed;
    height: 100vh;
    background-color: whitesmoke;
    z-index: 11;
    max-width: 240px;
    right: 0;
    padding: 10px;
    top: 71px;
    transition: 0.5s;
  }
  .signup-form-main-area {
    justify-content: center;
  }
  .welcome-row-wrapper {
    margin-top: 1.3rem;
  }
  .signup-inner-parent {
    padding: 0 20px;
  }
  .signup-header-area div {
    display: block;
    margin: 10px 0;
  }
  .signup-header-area div input {
    margin: 0;
  }
  .signup-form-wrapper {
    margin: 20px;
  }
  .hideInLeftInMobile {
    transition: 0.5s;
    transform: translateX(-240px);
    transition: 0.5s;
  }
  .headerHideinMobile {
    transition: 0.5s;
    transform: translateX(240px);
    transition: 0.5s;
  }
  .headerShowinMobile {
    transition: 0.5s;
    transform: translateX(0px);
    transition: 0.5s;
  }
  .showInLeftInMobile {
    transition: 0.5s;
    transform: translateX(0px);
    transition: 0.5s;
  }
  .sidebar-section {
    width: 100%;
    max-width: 240px;
    height: 100vh;
    position: fixed;
    display: block !important;
    left: 0;
    z-index: 12;
    bottom: 0;
    top: 70px;
    transition: 0.5s;
    background-color: white;
  }
  /* *****************service********* */
  .service-row-wrapper {
    height: auto;
    padding: 25px;
  }
  .service-packages-main-area .row .col {
    flex: 50%;
  }
  /* *************** */
  .security-settings-form-wrapper {
    margin: 0 auto;
  }
  /* ************ */

  .tabs-drugs-parent ul li{
    height: auto;
    font-size: 14px;
    padding: 5px 2px;
  }
  .aboutPageWrapper .main-area-inner-wrapper{
    margin: 0;
  }

  .ceo-profile-img-section .img-wrapper {
    width: 70%;
    overflow: hidden;
    margin: 0 auto;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .ceo-profile-img-section .img-wrapper img {
    width: 250px;
    height: auto;
    border-radius: 10px;
  }
  
}















/* loader */
.loader-wrapper{
  width: 100%;
  height: 100vh;
  background-color: rgba(255,255,255, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading {
  height: 0;
  width: 0;
  padding: 15px;
  border: 6px solid #008dd0;
  border-right-color: #dedede;
  border-radius: 22px;
  -webkit-animation: rotate 1s infinite linear;
  /* left, top and position just for the demo! */
  
}

@keyframes rotate {
  /* 100% keyframe for  clockwise. 
     use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}
.email-response{
  font-size: 14px;

  
}

.signup-box-input.loginfields.error-input {
  border: 1px solid red !important;
}

.error-message {
  color: red;
  font-size: 14px;
  margin: 0;
  display: block;
}

/* ******************************* */

.custom_multi_selector_container{
  width: 100%;
}
.label_area{
  width: 100%;
  height: 40px;
  background-color: white;
  border: thin solid #dedede;
  border-radius: 4px;
  overflow: hidden;
  padding: 10px;
  cursor: pointer;
}
.custom_dropdown_container{
  width: 100%;
  height: auto;
  max-height: 150px;
  background-color: whitesmoke;
  overflow-y: auto;
}
.custom_dropdown_container ul{
    list-style:none;
    padding: 0;
}
.custom_dropdown_container ul li{
  padding: 5px;
  border-bottom: 1px solid gray;
  cursor: pointer;
}
.custom_multiselect_item{
  width: 100%;
  height: auto;
  padding: 10px;
  display: flex;
  justify-content: flex-start; 
  border-bottom: thin solid #dedede;
  cursor: pointer;
}
.custom_multiselect_item p{
  margin: 0;
  text-transform: capitalize;
}
.notSelectedItemClass{
  background-color: transparent;
}
.selectedItemClass{
  background-color: gray;
}


@import url('./Media.css');



/* ********************** */
.ccTreatment-finish-card{
  width: 100%;
  height: auto;
  background-color: var(--sky);
  border-radius: 25px;
  overflow: hidden;
}
.ccTreatment-finish-card .drug-name-container{
text-align: center;
border-bottom: 1px solid #333;
width: 100%;
height: auto;
padding: 15px;
}
.ccTreatment-finish-card .drug-name-container h4{
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}
.ccTreatment-finish-card .drug-description-wrapper{
  width: 100%;
  height: 170px;
  background-color: transparent;
  padding: 15px;
  font-size: 16px;
}
.ccTreatment-finish-card-parent{
  width: 100%;
  height: auto  ;
}
.ccTreatment-finish-card-parent h3{
  text-align: center;
  color: var(--skyblue);
  margin-bottom: 10px;
}
.ccTreatmentGuideCarousel .owl-nav{
  display: flex!important;
  justify-content: center;
  margin-top: 2rem!important;
}
.ccTreatmentGuideCarousel .owl-nav .owl-prev,.ccTreatmentGuideCarousel .owl-nav .owl-next{
  width: 35px!important;
  height: 35px!important;
  font-size: 25px!important;
  padding: 20px!important;
  background-color: var(--sky)!important;
  display: flex!important;
  color: #000;
  font-weight: bold;
  align-items: center!important;
  justify-content: center!important;
}
.ccTreatmentGuideCarousel .owl-nav .owl-next span,.ccTreatmentGuideCarousel .owl-nav .owl-prev span
{
  line-height: 27px;
}
.ccTreatment-download-btn{
  /* background-color: var(--sky); */
  /* color: black; */
  padding: 8px 40px;
  font-size: 24px;
}


#numberofUniqueMechanism{
  display: none;
}


/* **************Results screen*************** */

.nct-number-row{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: thin solid var(--skyblue);
}
.nct-number-row p{
  color: var(--blue);
  text-transform: uppercase;
  font-size: 22px;
}
.nct-number-container{
  width: fit-content;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
}
.result-section h1{
  color: var(--blue);
  margin: 10px 0;
  line-height: 1.3;
  letter-spacing: 1px;
}
.blue_txt{
  color: var(--blue);
}
.result-section
{
  width: 100%;
  height: auto;
  max-height: 600px;
  overflow-y: auto; 
}
.results-tab-container
{
  width: 100%;
  height: auto;
  padding: 5px;
}
.result-tab-listing-area{
  width: 100%;
  height: auto;
  border-bottom: 2px solid var(--blue);
  margin-top: 1rem;
}
.result-tab-listing-area .react-tabs__tab--selected {
  background: var(--sky)!important;
  border: 2px solid var(--blue)!important;
  color: var(--blue);
  border-radius: 0!important;
}
.result-tab-listing-area  .react-tabs__tab{
  background: #fff;
  border: none;
  border: 2px solid var(--skyblue);
  color: var(--blue);
  padding: 4px 30px;
}
.react-tabs__tab:focus:after{
  background-color: transparent!important;
}
.study-tab-section{
  width: 100%;
  height: auto;
}
.study-tab-section .react-tabs {
  display: flex;
  
}
.study-tab-section .react-tabs .react-tabs__tab{
  width: 100%;
  background-color: white;
  padding: 15px;
  border-bottom: 3px solid var(--blue);
  padding-left: 20px;
}
.study-tab-section .react-tabs .react-tabs__tab--selected{
  border-left: none;
  border-right: none;
  border-top: 0px solid var(--blue);
  background-color: var(--sky);
  /* padding-left: 17px!important; */
  border-radius: 0;
}

.study-tab-section .react-tabs__tab-list{
max-width: 220px;
margin-right: 20px;
}
.study-tab-section  .react-tabs__tab-panel--selected{
  width: 100%;
  margin-left: 1rem;
  height: auto;
  padding: 10px;
}
.study-tab-content-detail-area{
  width: 100%;
  height: auto;
  background-color: #fff;
}
.study-tab-content-detail-area h2{
  font-weight: bold;
}
.tableview-results-parent{
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.8rem;
}
.subheading-result-tab-content{
  width: 100%;
  padding: 10px;
  padding: 18px;
  color: var(--blue);
  background-color: var(--sky);
}

.heart-in-saved-trials-btn
{
  width: 20px;
  position: absolute;
  left: 15px;
  top: 50%;
  margin-top: -10px ;
}
.healthcare-savedtrials-btn{
  height: 40px!important;
}
.healthcare-savedtrials-btn:focus{
  background-color: #0c206e;
}
.healthcare-trial-srch-btn{
  padding: 6px 70px!important;
}
.dropdownopen .fa-angle-right
{
  transform: rotate(90deg);
}
.css-1s2u09g-control
{
  background-color: #d6edf7!important;
  border: none!important;
  border-radius: 12px!important;
  overflow: hidden!important;
}
.clear_all_btn
{
  background-color: #e64040;
}
.form_footer
{
  width: 100%;
  position: sticky;
  bottom: -1px;
  background-color: #fff;
  padding: 10px;
 z-index: 999;
}


/* **********Clear modal section*********** */
/* Modal Overlay */
.clear-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Container */
.clear-modal {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 400px;
  width: 100%;
}

/* Modal Header */
.clear-modal-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.clear-modal-header h4 {
  margin: 0;
  color: #333;
  font-size: 18px;
  font-weight: 600;
}

/* Modal Body */
.clear-modal-body {
  padding: 20px 0;
  color: #555;
}

/* Modal Footer */
.clear-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.clear-modal-footer .btn {
  margin-left: 10px;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.clear-modal-footer .btn-cancel {
  background-color: #e53935;
  color: #fff;
}

.clear-modal-footer .btn-confirm {
  background-color: #4caf50;
  color: #fff;
}

.clear-modal-footer .btn:hover {
  opacity: 0.8;
}

.rdw-editor-main {
  overflow: auto;
  box-sizing: border-box;
  border: thin solid #dedede;
  min-height: 170px;
  overflow-y: auto;
  height: 170px;
  padding: .5rem;
}
.tracker-select .css-1s2u09g-control{
  background-color: transparent!important;
  border: thin solid #dedede!important;
}
.addTrackerDropdown .css-1s2u09g-control{
  background-color: #fff!important;
  border: thin solid #dedede!important;
  border-radius: unset!important;
}
.addTrackerDropdown .css-6j8wv5-Input{
  color: white!important;
}
.event-calendar-page .css-b62m3t-container {
  z-index: 1000;
}
/* *****Event calendar filters******* */
.calendar-filters-dropdown {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-top: 1rem;
}

.calendar-filters-dropdown-button {
  width: 100%;
  background: #fff;
  border: 1px solid #ddd;
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.calendar-filters-dropdown-arrow {
  transition: transform 0.3s;
  color: rgb(224, 219, 219);
}

.calendar-filters-dropdown-button.open .calendar-filters-dropdown-arrow {
  transform: rotate(180deg);
}

.calendar-filters-dropdown-content {
  background: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
  
}

.calendar-filters-dropdown-content label {
  
  display: block;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  align-items: center;
  border-bottom: thin solid #dedede;
  padding: .7rem 0;
  cursor: pointer;
  font-size: .9rem;
}

.calendar-filters-dropdown-content label input {
  margin-right: 1rem;
}
.color-symbol-eventCalendar{
  width: 10px;
  height: 10px;
  background-color: #000;
  display: flex;
  justify-self: flex-end;
  margin-right: .5rem;
}
.bigCalendar-wrapper{
  width: 100%;
  height: auto;
  padding: 2rem;
  background-color: #fff;
}
.mutationSearchInput {
  background-color: #fff!important;
  padding: 0 10px!important;
}

.forgotPassWrapper {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.otp-box-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-content: center;
}
.otp-box-wrapper input {
  width: 50px ;
  height: 50px;
  border-radius: 6px;
  border: 1px solid #dedede;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.3rem;
}

.RecNotFound {
  text-align: center;
  margin-top: 1rem;
}

.fileInputLabel {
  background-color: transparent; /* Clear background initially */
  color: #333; /* Dark text for contrast */
  padding: 10px ;
  text-align: center;
  display: block;
  cursor: pointer;
  border-radius: 5px;
  margin: 20px auto;
  width: 40%;
  border: 2px dashed #ccc; /* Dashed border */
  transition: all 0.3s;
}

.fileInputLabel:hover {
  background-color: #008dd0; /* Blue background on hover */
  color: white; /* White text on hover */
  border: 2px solid #008dd0; /* Solid blue border on hover */
}

  
.hideArrow {
  opacity: 0;
}


.input-error {
  border: 1px solid red;
}

div.swal2-container button.swal2-styled.swal2-confirm {
  background-color: var(--skyblue);
}



.saved-treatment-plans-table th,
.saved-treatment-plans-table td {
    text-align: center;
    vertical-align: middle;
}

.drugstatusTable-wrapper {
    overflow-x: auto;
    overflow-y: auto;
    max-height: 370px;
}

/* .drugstableIcon {
    margin-left: 8px;
} */

/* Ensuring the table width is 100% on all screens */
.saved-treatment-plans-table {
    width: 100%;
    table-layout: fixed; /* Helps in equal distribution of space among columns */
}

.swal2-icon.no-border {
  border: none;
}

.tooltipinput {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  background-color: white;
  color: black;
  padding: 10px;
  border-radius: 5px; 
  font-size: 14px;
  white-space: nowrap;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.search-box-area {
  position: relative;
}

input[type="search"]:focus + .tooltipinput {
  opacity: 1;
  visibility: visible;
}

.tooltipinput::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
  
}


@keyframes smoothFadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.select-menu-animation {
  animation: smoothFadeInUp 300ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

.select_search .react-select__menu {
  transform-origin: top;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;
}