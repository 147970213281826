/* CustomTooltip.css */
.tooltipContainer {
    position: relative;
    white-space: nowrap;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    cursor: default;
    max-width: 300px;
}

.tooltip {
    position: absolute;
    left: 50%; /* Center horizontally */
    bottom: 100%; /* Start just above the cell */
    transform: translateX(-50%); /* Pull back to the left by half of its own width */
    background-color: white;
    padding: 7px 8px;
    border-radius: 7px;
    z-index: 1;
    white-space: normal;
    min-width: 260px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    display: none; /* Hide by default, show on hover */
    font-size: 12px;
    margin-bottom: 10px; /* Adjust space between tooltip and cell */
}

.tooltipContainer:hover .tooltip {
    display: block; /* Show tooltip on hover */
}

.tooltip::after {
    content: "";
    position: absolute;
    top: 100%; /* Position it at the bottom of the tooltip */
    left: 50%; /* Center the arrow horizontally under the tooltip */
    transform: translateX(-50%); /* Center the arrow */
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
    box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.1);
}

.truncatedText {
  max-width: 600px; /* This will display roughly 50 characters based on font size */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}