.custom-multiselect-container{
    width: 165%;
}
.multiselect-label-area{
    width: 100%;
    height: auto;
    background-color: white;
    border: thin solid #008dd0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}
.mutliselect-options-wrap{
    width: 100%;
    height: 200px;
    background-color: white;
    border: thin solid #dedede;
    overflow-y: auto;
    overflow-x: hidden;
}
.mutliselect-options-wrap ul{
    list-style:none;
    padding: 0;
    margin: 0;
}
.mutliselect-options-wrap ul li{
    border-bottom: thin solid #dedede;
    padding: 10px;
    cursor: pointer;
    color: black;
    font-weight: bold;
}
.select-tags-container{
    width: 100%;
    height: 100%;
    max-height: 245px;
    overflow-y: auto;
    padding: 11px;
    background-color: #fff;
    border: thin solid #008dd0;
    margin-left: 20px;
}
.multiselect-section{
    width: 100%;
    display: flex;
}
.selected_li {
    background-color: #008dd0;
    color: white!important;
}
.selected-tag{
    width: fit-content;
    height: auto;
    padding: 5px 30px;
    font-size: 16px;
    margin: 10px 5px;
    background-color: #008dd0;
    border: thin solid white;
    border-radius: 20px;
    color: white;
    display: block;
    margin: 10px auto;
    font-weight: bold;
    position: relative;
}
.selected-tag-cross{
    width: 18px;
    position: absolute;
    top: 7px;
    right: 4px;
    cursor: pointer;
}

.select-tags-container::-webkit-scrollbar,.mutliselect-options-wrap::-webkit-scrollbar {
    width: 10px;
    position: relative;
    margin-left: 20px;
    left: -100px;
  }
  .select-tags-container::-webkit-scrollbar-track,.mutliselect-options-wrap::-webkit-scrollbar-track {
    background: #f1f1f1;
    border: 1px solid #008dd0;
    border-radius: 0px;
  }
  .select-tags-container::-webkit-scrollbar-thumb,.mutliselect-options-wrap::-webkit-scrollbar-thumb {
    background: #008dd0;
    border-radius: 10px;
  }